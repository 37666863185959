import React from 'react';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import WarningAmber from '@mui/icons-material/WarningAmber';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitleBar from './DialogTitleBar.jsx';

function CancelButton({ color, isDirty = false, onClick, message, children }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (!isDirty) {
      onClick();
    } else {
      setOpen(true);
    }
  }, [isDirty, onClick]);

  const handleDiscard = React.useCallback(() => {
    setOpen(false);
    onClick();
  }, [onClick]);

  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <Button
        color={color}
        variant="text"
        data-cy="cancel-button"
        onClick={handleClick}
        disabled={open}
      >
        {children}
      </Button>
      <Dialog open={open}>
        <DialogTitleBar title="Discard changes?" Icon={WarningAmber} iconColor="warning" />
        <DialogContent>
          {message || 'Any information you entered into the form will not be saved.'}
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
          <Box m={2}>
            <Button
              data-cy="discard-confirmation-button"
              variant="contained"
              color="secondary"
              onClick={handleDiscard}
            >
              Discard
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

CancelButton.propTypes = {
  color: PropTypes.string.isRequired,
  isDirty: PropTypes.bool,
  onClick: PropTypes.func,
  message: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CancelButton;
