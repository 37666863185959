import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

function NoneFound({
  // Props
  list,
  displayName,
}) {
  if (list.length) {
    return <Typography />;
  }

  return (
    <Typography align="center" sx={{ m: 3 }}>
      No {displayName} found
    </Typography>
  );
}

NoneFound.propTypes = {
  list: PropTypes.array.isRequired,
  displayName: PropTypes.string.isRequired,
};

export default NoneFound;
