import React from 'react';
import PropType from 'prop-types';

const SearchContext = React.createContext();

export function SearchProvider({ children }) {
  const [search, setSearch] = React.useState('');
  const [searchHelper, setSearchHelper] = React.useState('');
  const [searchFields, setSearchFields] = React.useState('');

  const value = React.useMemo(
    () => ({
      search,
      searchHelper,
      searchFields,
      setSearch,
      setSearchHelper,
      setSearchFields,
    }),
    [search, searchHelper, searchFields]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default SearchContext;

SearchProvider.propTypes = {
  children: PropType.node.isRequired,
};
