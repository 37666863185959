import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import PageLoading from './components/common/PageLoading.jsx';
import Layout from './components/Layout.jsx';
import useCustomTheme from './hooks/customizeReactTheme.jsx';
import ControlledProcesses from './pages/ControlledProcesses/index.jsx';
import Equipment from './pages/Equipment/index.jsx';
import Page404 from './pages/Page404.jsx';
import Parts from './pages/Parts/index.jsx';
import { addAccessTokenInterceptor } from './axiosClient.js';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const theme = useCustomTheme();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Use pathname for redirect - since we're staying within the application
      loginWithRedirect({
        appState: { returnTo: `${window.location.pathname}${window.location.search}` },
      });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <header className="App-header">
          {error && (
            <Container maxWidth="lg">
              <div>Oops... {error.message}</div>
              <br />
              <Button
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: window.location.origin },
                  })
                }
              >
                Login
              </Button>
            </Container>
          )}
          {!error && (isLoading || !isAuthenticated) && <PageLoading />}
          {isAuthenticated && (
            <Layout>
              <Routes>
                <Route path="/" element={<Parts />} />
                <Route path="/equipment" element={<Equipment />} />
                <Route path="/processes" element={<ControlledProcesses />} />
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Layout>
          )}
        </header>
      </ThemeProvider>
    </div>
  );
}

export default App;
