import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import httpClient from '../axiosClient.js';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function EmployeeForm({
  // Props
  email,
  setTableReload,
}) {
  const [, setSuccess] = useAlertSnackbar('success', 5000);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const defaultValues = React.useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email,
    }),
    [email]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);

      try {
        const encodedEmail = encodeURIComponent(data.email);
        await httpClient.put(`/api/users/${encodedEmail}`, {
          FirstName: data.firstName,
          LastName: data.lastName,
        });

        setSuccess('Successfully saved changes!');
        reset(defaultValues);
        setTableReload(true);
        setOpen(false);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [setSuccess, reset, defaultValues, setTableReload]
  );

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setOpen(false);
  }, [reset, defaultValues]);

  const validationRules = React.useMemo(() => {
    return {
      userEmail: {
        required: 'Please enter the user email',
        validate: (input) => {
          if (!isEmail(input)) {
            return 'Please enter a valid email address';
          }
          if (!input.match(/@tz(medical|monitoringsolutions).com$/)) {
            return 'Please enter a valid TZ Medical email address';
          }
          return true;
        },
      },
      firstName: {
        required: "Please enter the user's first name",
      },
      lastName: {
        required: "Please enter the user's last name",
      },
    };
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        startIcon={<PersonAddIcon />}
        data-cy="open-employee-form-button"
      >
        Add User
      </Button>
      <Dialog
        open={open}
        onClick={disablePropagation}
        data-cy="add-user-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        {error && <Alert message={error} setMessage={setError} level="error" />}
        <DialogTitleBar title="Add User" Icon={PersonAddIcon} iconColor="secondary" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} data-cy="first-name-input">
              <FormStringInput
                control={control}
                name="firstName"
                label="First Name"
                required
                rules={validationRules.firstName}
              />
            </Grid>
            <Grid item xs={12} data-cy="last-name-input">
              <FormStringInput
                control={control}
                name="lastName"
                label="Last Name"
                required
                rules={validationRules.lastName}
              />
            </Grid>
            <Grid item xs={12} data-cy="email-input">
              <FormStringInput
                control={control}
                name="email"
                label="Email"
                required
                rules={validationRules.userEmail}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              type="submit"
              loading={loading}
              disabled={!isDirty}
              variant="contained"
              color="secondary"
              data-cy="employee-submit-button"
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmployeeForm;
