import React from 'react';
import { DateTime } from 'luxon';

export function formatDate({
  // Props
  dateString,
  formatOptions = DateTime.DATE_MED,
}) {
  if (!dateString) {
    return '-';
  }
  return DateTime.fromISO(dateString).toUTC().toLocaleString(formatOptions);
}

export default React.memo(formatDate);
