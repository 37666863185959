import React from 'react';

const PageContext = React.createContext();

export function PageProvider({ children }) {
  const [editMode, setEditMode] = React.useState(false);
  const [PageButtons, setPageButtons] = React.useState(null);

  // Memoize the value to reduce rerenders
  const value = React.useMemo(
    () => ({
      PageButtons,
      setPageButtons,
      editMode,
      setEditMode,
    }),
    [PageButtons, editMode]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export default PageContext;
