import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { DatePicker } from '@mui/x-date-pickers';

function FormDatePicker({
  // Props
  control,
  name,
  label,
  id,
  views = ['year', 'month', 'day'],
  required = true,
  disabled = false,
  disableFuture,
  variant = 'standard',
  rules,
  'data-cy': dataCy,
}) {
  return (
    <Controller
      name={name}
      control={control}
      data-cy={`controller-${id}`}
      shouldUnregister
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          id={id}
          views={views}
          disableFuture={disableFuture}
          data-cy={dataCy}
          slotProps={{
            textField: {
              fullWidth: true,
              variant,
              required,
              error: !!error,
              helperText: error?.message,
            },
          }}
        />
      )}
    />
  );
}

FormDatePicker.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * List of views to use when rendering the picker
   */
  views: PropTypes.arrayOf(PropTypes.string),

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` the future dates cannot be picked
   */
  disableFuture: PropTypes.bool,

  /**
   * Control the rendered variant of the MUI TextField component
   */
  variant: PropTypes.string,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * Selector for E2E testing in Cypress
   */
  'data-cy': PropTypes.string,
};

export default FormDatePicker;
