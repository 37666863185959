import React from 'react';
import { DateTime } from 'luxon';

const calculateStdDev = (dataValues, mean) => {
  const diffSquaredValues = dataValues.map((sample) => (sample - mean) ** 2);
  return diffSquaredValues.length > 1
    ? Math.sqrt(
        diffSquaredValues.reduce((sum, diffSquared) => sum + diffSquared) /
          (diffSquaredValues.length - 1)
      )
    : 0;
};

const useControlLimits = (pastMeasurements = [], currentInputs = []) => {
  return React.useMemo(() => {
    const sanitizedPastMeasurements =
      pastMeasurements.length > 0
        ? pastMeasurements
            // Sort OLDEST first
            .toSorted(
              (a, b) =>
                DateTime.fromISO(a.DateOfCollection).toMillis() -
                DateTime.fromISO(b.DateOfCollection).toMillis()
            )
            // Limit to the last 4 entries
            .slice(pastMeasurements.length > 4 ? pastMeasurements.length - 4 : 0)
        : [];

    // Remove invalid user inputs to avoid breaking the math
    const sanitizedCurrentInputs = currentInputs.filter(
      (val) => !([null, undefined, ''].includes(val) || Number.isNaN(Number(val)))
    );

    const dataValues = [
      // Include past measurements if any exist
      // (Flatten the samples into a 1-dimensional array)
      ...sanitizedPastMeasurements.flatMap((m) =>
        m.TestSamples.map((testSample) => testSample.Value)
      ),
      // Include the current input data if we have less history than we'd like
      ...(pastMeasurements.length < 4 ? sanitizedCurrentInputs.map((val) => Number(val)) : []),
    ];

    if (!dataValues.length) {
      return {
        mean: 0,
        stdDev: 0,
        upperLimit: 0,
        lowerLimit: 0,
        xValues: [],
        yValues: [],
      };
    }

    const mean = dataValues.reduce((sum, sample) => sum + sample) / dataValues.length;
    const stdDev = calculateStdDev(dataValues, mean);

    return {
      mean,
      stdDev,
      ...(stdDev > 0 && {
        upperLimit: mean + 3 * stdDev,
        lowerLimit: mean - 3 * stdDev,
      }),
      xValues: [
        ...sanitizedPastMeasurements.map((m) => m.DateOfCollection),
        DateTime.now().toISODate(),
      ],
      yValues: [...sanitizedPastMeasurements, sanitizedCurrentInputs].map((list) => {
        if (list.TestSamples) {
          return (
            list.TestSamples.reduce((sum, testSample) => sum + testSample.Value, 0) /
            list.TestSamples.length
          );
        }
        if (!list.length) {
          return null;
        }
        return list.reduce((sum, value) => sum + Number(value), 0) / list.length;
      }),
    };
  }, [pastMeasurements, currentInputs]);
};

export default useControlLimits;
