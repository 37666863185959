import React from 'react';
import PropTypes from 'prop-types';

export function readablePart({
  // Props
  part,
}) {
  if (!part) {
    return '-';
  }
  return `${part.PartNumber}${part.Description ? ` - ${part.Description}` : ''}`;
}

readablePart.propTypes = {
  part: PropTypes.shape({
    PartNumber: PropTypes.string,
    Description: PropTypes.string,
  }),
};

export default React.memo(readablePart);
