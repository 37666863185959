import React from 'react';
import PropTypes from 'prop-types';

import { readablePart } from './ReadablePart.jsx';

export function readableControlledProcess({
  // Props
  process,
  documents,
}) {
  const document = documents.find((doc) => doc.id === process.DocumentId);
  const documentName = document ? document.name : '';
  const toolSuffix = process?.ToolPart ? ` with ${readablePart({ part: process.ToolPart })}` : '';

  return `${process.DocumentId} - ${documentName} (${process.Equipment.Serial} - ${process.Equipment.Name}${toolSuffix})`;
}

readableControlledProcess.propTypes = {
  process: PropTypes.shape({
    DocumentId: PropTypes.string.isRequired,
    ToolPart: PropTypes.shape({
      PartNumber: PropTypes.string,
      Description: PropTypes.string,
    }),
    Equipment: PropTypes.shape({
      Serial: PropTypes.string,
      Name: PropTypes.string,
    }).isRequired,
  }),
};

export default React.memo(readableControlledProcess);
