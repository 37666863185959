import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function FormAutocompleteInput({
  // Props
  control,
  name,
  label,
  type = 'text',
  required = false,
  disabled = false,
  multiple,
  options,
  variant = 'standard',
  rules,
  'data-cy': dataCy,
}) {
  const context = useFormContext();

  const getOptionLabel = React.useCallback((option) => option?.name || '', []);
  const getOptionKey = React.useCallback((option) => option?.id || '', []);

  const render = React.useCallback(
    ({ field: { onChange, name: fieldName, value }, fieldState: { error } }) => {
      const handleChange = (_, val) => onChange(val);
      return (
        <Autocomplete
          id={fieldName}
          openOnFocus
          autoHighlight
          disabled={disabled}
          value={value}
          options={options}
          multiple={multiple}
          getOptionLabel={getOptionLabel}
          getOptionKey={getOptionKey}
          onChange={handleChange}
          data-cy={dataCy}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              name={fieldName}
              type={type}
              fullWidth
              variant={variant}
              label={label}
              disabled={disabled}
              error={!!error}
              helperText={error?.message}
              required={required}
            />
          )}
        />
      );
    },
    [
      dataCy,
      disabled,
      getOptionKey,
      getOptionLabel,
      label,
      multiple,
      options,
      required,
      type,
      variant,
    ]
  );

  return (
    <Controller
      shouldUnregister
      name={name}
      control={context?.control || control}
      render={render}
      rules={rules}
    />
  );
}

FormAutocompleteInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  /**
   * Type of the `input` element
   */
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'search', 'tel', 'url']),

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Options for the autocomplete input.
   */
  options: PropTypes.array.isRequired,

  /**
   * Set to `true` to allow selection of multiple options
   */
  multiple: PropTypes.bool,

  /**
   * Control the rendered variant of the MUI TextField component
   */
  variant: PropTypes.string,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * Allows for inputs that are not included in the options list
   */
  freeSolo: PropTypes.bool,

  /**
   * Selector for E2E testing in Cypress
   */
  'data-cy': PropTypes.string,
};

export default FormAutocompleteInput;
