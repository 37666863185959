import React from 'react';
import PropTypes from 'prop-types';

export function readableEquipment({
  // Props
  equipment,
}) {
  if (!equipment) {
    return '-';
  }
  return `${equipment.Serial}${equipment.Name ? ` - ${equipment.Name}` : ''}`;
}

readableEquipment.propTypes = {
  equipment: PropTypes.shape({
    Serial: PropTypes.string,
    Name: PropTypes.string,
  }),
};

export default React.memo(readableEquipment);
