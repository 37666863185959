import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/icons-material/Menu';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import ProfileDropdown from './ProfileDropdown.jsx';
import SearchBar from './SearchBar.jsx';

function TopBar({
  // Props
  onClick,
  onClose,
}) {
  const ultraNarrow = useMediaQuery('(max-width:399px)');

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs="auto">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onClick}
            >
              <Menu />
            </IconButton>
          </Grid>
          <Grid item xs="auto" sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography variant="h6" noWrap component="div">
              Progress Tracker
            </Typography>
          </Grid>
          <Grid item xs align="center" onClick={onClose}>
            <SearchBar />
          </Grid>
          {!ultraNarrow && (
            <Grid item xs="auto">
              <ProfileDropdown />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

TopBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};
