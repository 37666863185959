import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import DocumentContext from '../../contexts/DocumentContext.jsx';
import ExternalLink from './ExternalLink.jsx';

const KT_URL = 'https://kt.tzmedical.com/knowledgetree/view.php?fDocumentId=';

function DisplayKTDocument({
  // Props
  documentId,
  type = 'KT',
  displayExtraDetails = false,
}) {
  const { documents } = React.useContext(DocumentContext);
  const documentData = React.useMemo(
    () => documents.find((doc) => doc.id === documentId) || '',
    [documents, documentId]
  );

  return (
    <div>
      <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
        {type} Document Title
      </Typography>
      <div data-cy={`${type.toLowerCase()}-name`}>
        {documentData?.name ? (
          <ExternalLink link={`${KT_URL}${documentData.id}`} title={documentData?.name} />
        ) : (
          <strong>
            {documentId && !documentData ? 'Invalid Document Id' : 'No Document Provided'}
          </strong>
        )}
      </div>
      {displayExtraDetails && documentData?.last_approved_version && (
        <>
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
            {type} Document Last Approved Version
          </Typography>
          {documentData?.last_approved_version}
        </>
      )}
      {displayExtraDetails && documentData?.workflow_state && (
        <>
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
            {type} Document Workflow State
          </Typography>
          {documentData?.workflow_state}
        </>
      )}
    </div>
  );
}

DisplayKTDocument.propTypes = {
  documentId: PropTypes.number,
  type: PropTypes.string,
  displayExtraDetails: PropTypes.bool,
};

export default DisplayKTDocument;
