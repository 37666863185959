import React from 'react';

import DocumentContext from '../contexts/DocumentContext.jsx';

function MaintenanceRequirementsLabel({
  // Props
  requirement,
}) {
  const { documents } = React.useContext(DocumentContext);
  const document = documents.find(({ id }) => id === requirement.ProcedureId);
  return `${document?.name} - ${requirement.IntervalPeriod} ${requirement.IntervalUnits}`;
}

export default React.memo(MaintenanceRequirementsLabel);
