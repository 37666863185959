import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import DocumentLink from './common/DocumentLink.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

function MaintenanceRequirementsForm({
  // props
  equipment,
  requirement,
  setTableReload,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error and Success alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      // if the interval unit is unknown due to a migration, require the field to be filled out
      intervalUnits: requirement?.IntervalUnits || '',
      intervalPeriod: requirement?.IntervalPeriod || '',
      procedureId: requirement?.ProcedureId || '',
      notes: requirement?.Notes || '',
    }),
    [requirement]
  );

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues,
    shouldUnregister: true,
  });
  const { isDirty } = useFormState({ control });

  //---------------------------------------------------------------------------
  // Form Options
  //---------------------------------------------------------------------------
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);
  const onSubmit = React.useCallback(
    async (data) => {
      // disable the submit button and display the spinner
      setLoading(true);

      try {
        const encodedSerial = encodeURIComponent(equipment.Serial);
        if (requirement) {
          await axios({
            method: 'PUT',
            url: `/api/equipment/${encodedSerial}/maintenance-requirements/${requirement.Id}`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedureId,
              disabled: data.disabled ?? requirement?.IsDisabled ?? false,
              notes: data.notes,
            },
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/equipment/${encodedSerial}/maintenance-requirements`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedureId,
              disabled: false,
              notes: data.notes,
            },
          });
        }

        // reset and close the modal
        handleClose();

        // update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
        setSuccess(
          requirement
            ? `Successfully edited a Maintenance Requirement on ${equipment.Name}`
            : `Successfully added a new Maintenance Requirement to ${equipment.Name}`
        );
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      }
      setLoading(false);
    },
    [equipment.Name, equipment.Serial, requirement, setSuccess, setTableReload, handleClose]
  );

  const intervalUnitOptions = React.useMemo(() => {
    const options = [
      { name: 'Days', id: 'Days' },
      { name: 'Operating Hours', id: 'Operating Hours' },
      { name: 'Months', id: 'Months' },
      { name: 'Weeks', id: 'Weeks' },
      { name: 'Years', id: 'Years' },
    ];

    // if the unit is defined as 'unknown' because of a migration, add the option
    // but require the user to update it
    if (requirement?.IntervalUnits?.toLowerCase() === 'unknown') {
      options.push({
        name: <i>Unknown</i>,
        id: 'Unknown',
        disabled: true,
      });
    }

    return options;
  }, [requirement?.IntervalUnits]);

  //---------------------------------------------------------------------------
  // Autocomplete lists of Process Ids
  //---------------------------------------------------------------------------
  const { documents } = React.useContext(DocumentContext);
  const procedureIdField = Number(watch('procedureId'));

  //---------------------------------------------------------------------------
  // Disable Confirmation Form
  //---------------------------------------------------------------------------
  const handleDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: !requirement?.IsDisabled,
    });
  }, [defaultValues, onSubmit, requirement?.IsDisabled]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {requirement ? (
        <IconButton
          aria-label="edit-maintenance-requirement"
          color="secondary"
          onClick={handleOpen}
          data-cy={`edit-maintenance-requirement-${requirement.EquipmentSerial}-${requirement.IntervalPeriod}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<PendingActionsIcon />}
          data-cy="add-requirements"
        >
          Add Requirement
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-cy="maintenance-requirements-form"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="dialog-error" />
        <DialogTitleBar
          title={`${requirement ? 'Edit' : 'Add'} Maintenance Requirement - ${equipment.Name} (${equipment.Serial})`}
          Icon={PendingActionsIcon}
          iconColor="secondary"
        />
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={6} xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormStringInput
                    control={control}
                    name="intervalPeriod"
                    label="Interval Period"
                    type="number"
                    textAlign="right"
                    required
                    disabled={requirement?.IsDisabled}
                    rules={{
                      required: 'Please enter the period for this maintenance interval',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormStringInput
                    control={control}
                    name="intervalUnits"
                    label="Interval Units"
                    required
                    disabled={requirement?.IsDisabled}
                    options={intervalUnitOptions}
                    rules={{
                      required: 'Please select the units for this maintenance interval',
                      validate: {
                        validateIntervalUnit: (value) => {
                          if (value?.toLowerCase() === 'unknown') {
                            return 'Unknown is not a maintenance interval';
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormStringInput
                    control={control}
                    name="procedureId"
                    label="Procedure ID"
                    type="number"
                    required
                    disabled={requirement?.IsDisabled}
                    rules={{
                      required: 'Please enter the document ID for this maintenance procedure',
                      validate: {
                        validateDocumentId: (value) => {
                          if (!documents.some((doc) => doc?.id === Number(value))) {
                            return 'Please provide a valid document ID for this maintenance procedure';
                          }

                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormStringInput control={control} name="notes" label="Notes" rows={4} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <DocumentLink documentId={procedureIdField} displayCaption data-cy="procedure-name" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2} sx={{ marginRight: 'auto' }}>
            {!!requirement && (
              <ConfirmDisable
                handleConfirmation={handleDisableConfirmation}
                enable={requirement.IsDisabled}
                confirmKeyword={requirement.IsDisabled ? 'ENABLE' : 'DISABLE'}
                name="requirement"
                Icon={PendingActionsIcon}
              />
            )}
          </Box>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              data-cy="submit-requirements"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {requirement ? 'Edit' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaintenanceRequirementsForm;
