import React from 'react';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

function Pagination({ pageSize, count, page, setPage, 'data-cy': dataCy }) {
  const start = React.useMemo(() => {
    if (count > 0) {
      return page * pageSize + 1;
    }
    return 0;
  }, [count, page, pageSize]);
  const end = Math.min((page + 1) * pageSize, count);
  const maxPage = Math.ceil(count / pageSize) - 1;

  const setFirstPage = React.useCallback(() => setPage(0), [setPage]);
  const setPreviousPage = React.useCallback(() => setPage((previous) => previous - 1), [setPage]);
  const setNextPage = React.useCallback(() => setPage((previous) => previous + 1), [setPage]);
  const setLastPage = React.useCallback(() => setPage(maxPage), [setPage, maxPage]);

  return (
    <Box data-cy={dataCy}>
      <Grid
        container
        sx={{ p: 1.5 }}
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Grid item xs={4}>
          <IconButton color="secondary" onClick={setFirstPage} disabled={page <= 0}>
            <FirstPage />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={setPreviousPage}
            data-cy="prev-page-button"
            disabled={page <= 0}
          >
            <ChevronLeft />
          </IconButton>
        </Grid>
        <Grid item xs={4} align="center">
          <Typography textAlign="center" padding="13px" fontSize="15px">
            Showing {start} - {end} of {count}
          </Typography>
        </Grid>
        <Grid item xs={4} align="right">
          <IconButton
            color="secondary"
            onClick={setNextPage}
            data-cy="next-page-button"
            disabled={page >= maxPage}
          >
            <ChevronRight />
          </IconButton>
          <IconButton color="secondary" onClick={setLastPage} disabled={page >= maxPage}>
            <LastPage />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  'data-cy': PropTypes.string,
};

export default Pagination;
