/* eslint-disable no-param-reassign */
import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import CancelIcon from '@mui/icons-material/Cancel';
import TuneIcon from '@mui/icons-material/Tune';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import { splitFilterInput } from '@tzmedical/react-hooks/useFilter';

import SearchContext from '../contexts/SearchContext.jsx';
import SearchHelperRow from './SearchHelperRow.jsx';

function SearchHelper({
  // Props
  anchorEl,
  autoFillValues,
}) {
  const { search, searchHelper, setSearch, searchFields } = React.useContext(SearchContext);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(() => {
    const defaults = {};
    if (Array.isArray(searchHelper)) {
      // First, construct the defaults
      searchHelper.forEach((helper) => {
        if (helper.variant === 'relative' || helper.variant === 'inequality') {
          defaults[`${helper.keyword}-${helper.variant}`] = '>';
        }
        if (helper.variant === 'negatable') {
          defaults[`${helper.keyword}-negatable`] = '+';
        }
        if (helper.variant === 'toggle') {
          defaults[`${helper.keyword}-negatable`] = '';
        }
        defaults[helper.keyword] = '';
      });

      // Then, tweak the defaults based on the current search input
      const currentSearch = splitFilterInput(search, searchFields);
      currentSearch.forEach(({ key, value, negated, searchType }) => {
        // For each blob in the search bar, we need to find the associated helper element.
        const helper = searchHelper.find((el) => {
          // This magic is how we deal with global search blobs, which don't actually have keywords
          if (!key && !negated) {
            return el.keyword === '+';
          }
          if (!key && negated === '-') {
            return el.keyword === '-';
          }
          return el.keyword === key;
        });
        // NOTE: Not all possible searches have a helper! Un-matched blobs will be left alone.
        if (helper) {
          if (helper.variant === 'relative' || helper.variant === 'inequality') {
            // Multiple relative entries in the search bar will just replace each other with the last one winning
            defaults[`${helper.keyword}-${helper.variant}`] = searchType;
            defaults[helper.keyword] = value;
          }
          if (helper.variant === 'negatable') {
            if (defaults[helper.keyword]) {
              // Add a "-" for negation based on XOR of the dropdown and the input's negation
              const isNegated =
                (negated === '-') !== (defaults[`${helper.keyword}-negatable`] === '-');
              defaults[helper.keyword] =
                `${defaults[helper.keyword]} ${isNegated ? '-' : ''}${value}`;
            } else {
              defaults[`${helper.keyword}-negatable`] = negated === '-' ? '-' : '+';
              defaults[helper.keyword] = value;
            }
          }
          if (helper.variant === 'toggle') {
            // Multiple toggles will just replace each other with the last one winning
            defaults[`${helper.keyword}-negatable`] = negated === '-' ? '-' : '+';
          }
          if (helper.variant === 'global') {
            // Globals are already split by negation, so we just append here
            if (defaults[helper.keyword]) {
              defaults[helper.keyword] = `${defaults[helper.keyword]} ${value}`;
            } else {
              defaults[helper.keyword] = value;
            }
          }
        }
      });
    }
    return defaults;
  }, [search, searchFields, searchHelper]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    resetOptions: { keepDefaultValues: false },
    shouldUnregister: true,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    // Update the form's default values based on the current value of the search bar.
    reset(defaultValues);
    setOpen(true);
  }, [defaultValues, reset]);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = React.useCallback(
    async (data) => {
      // Construct the search string based on the searchHelper config
      const newSearch = searchHelper?.reduce((string, row) => {
        if (row.variant === 'toggle') {
          if (data[`${row.keyword}-negatable`] !== '') {
            const isNegated = data[`${row.keyword}-negatable`] === '-';
            string += isNegated ? `-${row.keyword} ` : `${row.keyword} `;
          }
        } else if (data[row.keyword] !== '') {
          const wordsOrPhrases = splitFilterInput(data[row.keyword]);
          // wordsOrPhrases returns an array like [{fields: [], value: "input"}]
          string += wordsOrPhrases.reduce((substring, phrase) => {
            const value = phrase.value.includes(' ') ? `"${phrase.value}"` : phrase.value;
            const { negated } = phrase;
            if (row.variant === 'global') {
              if (row.keyword === '-') {
                substring += negated === '-' ? `${value} ` : `-${value} `;
              } else {
                substring += `${negated || ''}${value} `;
              }
            } else if (row.variant === 'negatable') {
              // Add a "-" for negation based on XOR of the dropdown and the input's negation
              const isNegated = (data[`${row.keyword}-negatable`] === '-') !== (negated === '-');
              if (isNegated) {
                substring += `-${row.keyword}:${value} `;
              } else {
                substring += `${row.keyword}:${value} `;
              }
            } else if (row.variant === 'relative' || row.variant === 'inequality') {
              const relativeString = data[`${row.keyword}-${row.variant}`];
              if (relativeString === '=') {
                substring += `${row.keyword}:${value} `;
              } else {
                substring += `${row.keyword}:${relativeString}${value} `;
              }
            } else if (row.variant === 'toggle') {
              const isNegated = data[`${row.keyword}-negatable`] === '-';
              if (isNegated) {
                substring += `-${row.keyword} `;
              } else {
                substring += `${row.keyword} `;
              }
            } else {
              substring += `${value} `;
            }
            return substring;
          }, '');
        }
        return string;
      }, '');

      setSearch(newSearch);
      setOpen(false);
      window.scrollTo(0, 0);
    },
    [searchHelper, setSearch]
  );

  const clearSearch = React.useCallback(() => {
    setSearch('');
    reset();
    window.scrollTo(0, 0);
  }, [reset, setSearch]);

  return (
    <>
      <ButtonGroup variant="outlined">
        {search && (
          <IconButton
            aria-label="clear-search"
            data-cy="clear-search"
            color="inherit"
            onClick={clearSearch}
          >
            <CancelIcon />
          </IconButton>
        )}
        {searchHelper && (
          <IconButton
            aria-label="open-search-helper"
            data-cy="open-search-helper"
            color="inherit"
            onClick={handleOpen}
          >
            <TuneIcon />
          </IconButton>
        )}
      </ButtonGroup>
      {searchHelper && (
        <Popover
          id="search-helper"
          anchorEl={anchorEl.current}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ width: anchorEl?.current?.offsetWidth, m: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              {searchHelper.map((helper) => (
                <SearchHelperRow
                  key={helper.keyword}
                  label={helper.label}
                  variant={helper.variant}
                  control={control}
                  keyword={helper.keyword}
                  options={helper.options}
                  autoFillValues={autoFillValues}
                />
              ))}
              <Box sx={{ textAlign: 'right', mt: 3 }}>
                <Button color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  data-cy="submit-search"
                  variant="contained"
                  type="submit"
                  sx={{ ml: 2 }}
                >
                  Search
                </Button>
              </Box>
            </form>
          </Box>
        </Popover>
      )}
    </>
  );
}

SearchHelper.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  autoFillValues: PropTypes.array,
};

export default SearchHelper;
