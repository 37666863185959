import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import PropTypes from 'prop-types';

import ConstructionIcon from '@mui/icons-material/Construction';
import Edit from '@mui/icons-material/Edit';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

import LoadingButton from '@mui/lab/LoadingButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useCheckExists } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import FormParametersInput from '../pages/ControlledProcesses/FormParametersInput.jsx';
import unitOptions from '../units.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DialogTitleBar from './common/DialogTitleBar.jsx';
import DisplayKTDocument from './common/DisplayKTDocument.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';

const unitSelectOptions = unitOptions.map((unit) => ({
  id: unit,
  value: unit,
  name: unit,
}));

function ControlledProcessForm({
  // Props
  process,
  equipment,
  partList,
  setTableReload,
  disabled,
  'data-cy': dataCy,
}) {
  const { documents } = React.useContext(DocumentContext);
  const checkDocumentExists = useCheckExists(
    documents,
    'id',
    null,
    'Please provide a valid document ID for this controlled process.'
  );
  const parametersDefaultValues = React.useMemo(
    () =>
      process?.Parameters?.map((p) => ({
        name: p.Name,
        value: Number(p.Value),
        units: p.Units,
      })) || [],
    [process?.Parameters]
  );

  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 5_000);
  const formTitle = React.useMemo(
    () => (process?.Id ? 'Update Process' : 'Add Process'),
    [process?.Id]
  );
  const buttonText = React.useMemo(() => (process?.Id ? 'Update' : 'Add'), [process?.Id]);

  const blockClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [equipmentList, setEquipmentList] = React.useState([]);

  const equipmentOptions = React.useMemo(
    () =>
      equipmentList?.map((equipmentOption) => ({
        name: `[${equipmentOption?.Serial}] ${equipmentOption?.Name}`,
        id: equipmentOption?.Serial,
        key: equipmentOption?.Serial,
      })) || [],
    [equipmentList]
  );
  const partOptions = React.useMemo(
    () =>
      partList?.map((part) => ({
        name: `[${part?.PartNumber}] ${part?.Description}`,
        id: part?.PartNumber,
        key: part?.PartNumber,
      })) || [],
    [partList]
  );
  const getEquipmentAndParts = React.useCallback(async () => {
    try {
      if (!error) {
        const equipmentResponse = await axios({
          method: 'GET',
          url: '/api/equipment',
          params: { include: [] }, // Skip the table JOINs!
        });

        setEquipmentList(equipmentResponse.data);

        setError(null);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error]);

  React.useEffect(() => {
    if (open) {
      getEquipmentAndParts();
    }
  }, [getEquipmentAndParts, open]);

  const [loading, setLoading] = React.useState(false);
  const equipmentDefaultValue = React.useMemo(() => {
    if (equipment) {
      return {
        name: `[${equipment.Serial}] ${equipment.Name}`,
        id: equipment.Serial,
        key: equipment.Serial,
      };
    }
    if (process?.Equipment) {
      return {
        name: `[${process.Equipment.Serial}] ${process.Equipment.Name}`,
        id: process.Equipment?.Serial,
        key: process.Equipment?.Serial,
      };
    }
    return '';
  }, [equipment, process]);

  const toolPartDefaultValue = React.useMemo(() => {
    if (process?.ToolPart) {
      return {
        name: `[${process.ToolPart?.PartNumber}] ${process.ToolPart?.Description}`,
        id: process.ToolPart?.PartNumber,
        key: process.ToolPart?.PartNumber,
      };
    }
    return '';
  }, [process]);

  const consumableDefaultValues = React.useMemo(
    () =>
      process?.Parts?.map((part) => ({
        name: `[${part.PartNumber}] ${part.Description}`,
        id: part.PartNumber,
        key: part.PartNumber,
      })) || [],
    [process]
  );

  const defaultValues = React.useMemo(
    () => ({
      equipmentSerial: equipmentDefaultValue,
      toolPartNumber: toolPartDefaultValue,
      parameters: parametersDefaultValues,
      consumables: consumableDefaultValues,
      min: process?.TestMinimum ?? '',
      max: process?.TestMaximum ?? '',
      testType: process?.TestType ?? '',
      testUnits: process?.TestUnits ?? 'unitless',
      requiredSampleCount: process?.RequiredSampleCount ?? '',
      documentId: process?.DocumentId ?? '',
    }),
    [
      process?.TestMinimum,
      process?.TestMaximum,
      process?.TestType,
      process?.TestUnits,
      process?.RequiredSampleCount,
      process?.DocumentId,
      toolPartDefaultValue,
      equipmentDefaultValue,
      parametersDefaultValues,
      consumableDefaultValues,
    ]
  );

  // Make sure to unregister the inputs to avoid breaking the fieldArray inputs
  const { handleSubmit, control, reset, watch, clearErrors } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const documentId = watch('documentId');
  const memoizedDocumentId = React.useMemo(() => Number(documentId), [documentId]);

  const { isDirty } = useFormState({ control });
  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);

      try {
        const fields = {
          equipmentSerial: data.equipmentSerial.id,
          toolPartNumber: data.toolPartNumber.id,
          // Add the `order` property based on the array index so it will always render the same
          parameters: data.parameters.map((parameter, index) => ({ ...parameter, order: index })),
          min: data.min,
          max: data.max,
          testType: data.testType,
          testUnits: data.testUnits,
          requiredSampleCount: data.requiredSampleCount,
          documentId: data.documentId,
          consumables: data.consumables?.map((c) => c.id),
          // Default the version to 1 on creation, also don't let the form code mess with this value!
          version: process?.Version || 1,
        };

        if (!process?.Id) {
          await axios({
            method: 'POST',
            url: '/api/processes/',
            data: fields,
          });
        } else {
          await axios({
            method: 'PATCH',
            url: `/api/processes/${process?.Id}`,
            data: fields,
          });
        }

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);
        setSuccess('Successfully saved changes!');

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
      } catch (err) {
        setError(err.response?.data?.error || err.message);
      }
      setLoading(false);
    },
    [process?.Version, process?.Id, reset, defaultValues, setSuccess, setTableReload]
  );

  const handleOpen = React.useCallback((event) => {
    // Stop propagation of click event so clicking inside the edit modal
    // doesn't cause the parent equipment row to expand after.
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setError(null);

    setOpen(false);
  }, [defaultValues, reset, setOpen]);

  const equipmentSerialRules = React.useMemo(
    () => ({ required: 'Please select an equipment!' }),
    []
  );

  const testTypeRules = React.useMemo(() => ({ required: 'Test type is required!' }), []);

  const testMinRules = React.useMemo(
    () => ({
      required: 'Minimum is required!',
      validate: (minimum) => {
        if (Number(minimum) > Number(watch('max'))) {
          return 'Minimum must be less than maximum!';
        }
        return true;
      },
    }),
    [watch]
  );

  const testMaxRules = React.useMemo(
    () => ({
      required: 'Maximum is required!',
      validate: (maximum) => {
        if (Number(maximum) < Number(watch('min'))) {
          return 'Maximum must be greater than minimum!';
        }
        return true;
      },
    }),
    [watch]
  );

  const testUnitsRules = React.useMemo(() => ({ required: 'Test units are required!' }), []);

  const documentIdRules = React.useMemo(
    () => ({
      required: 'Document ID is required!',
      validate: {
        validateDocumentId: (value) => checkDocumentExists(Number(value)),
      },
    }),
    [checkDocumentExists]
  );

  const sampleCountRules = React.useMemo(() => ({ required: 'Sample count is required!' }), []);

  const backdropOpen = React.useMemo(
    () => (open && partList?.length === 0) || equipmentList?.length === 0,
    [equipmentList?.length, open, partList?.length]
  );

  return (
    <>
      {process ? (
        <IconButton data-cy={dataCy} aria-label="edit" color="secondary" onClick={handleOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          data-cy={dataCy}
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          disabled={disabled}
          startIcon={<ConstructionIcon />}
          fullWidth
        >
          Add Process
        </Button>
      )}
      <Dialog
        open={open}
        onClick={blockClick}
        maxWidth="lg"
        fullWidth
        data-cy="controlled-process-dialog"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitleBar title={formTitle} Icon={ConstructionIcon} iconColor="secondary" />
        <DialogContent>
          {/* Loading spinner */}
          <Backdrop
            data-cy="loading-backdrop"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <FormAutocompleteInput
                control={control}
                name="equipmentSerial"
                label="Equipment"
                required
                disabled={!!process || !!equipment}
                options={equipmentOptions}
                rules={equipmentSerialRules}
                data-cy="equipment-input"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAutocompleteInput
                control={control}
                name="toolPartNumber"
                label="Tool Part"
                options={partOptions}
                data-cy="tool-part-input"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                name="testType"
                label="Test Type"
                required
                rules={testTypeRules}
                data-cy="test-type-input"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormStringInput
                    control={control}
                    name="min"
                    label="Minimum"
                    type="number"
                    required
                    rules={testMinRules}
                    data-cy="min-input"
                  />
                </Grid>
                <Grid item xs={2}>
                  <center>
                    <br />
                    <SyncAltIcon />
                  </center>
                </Grid>
                <Grid item xs={3}>
                  <FormStringInput
                    control={control}
                    name="max"
                    label="Maximum"
                    type="number"
                    required
                    rules={testMaxRules}
                    data-cy="max-input"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormStringInput
                    control={control}
                    name="testUnits"
                    label="Test Units"
                    required
                    options={unitSelectOptions}
                    rules={testUnitsRules}
                    data-cy="test-units-input"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                name="documentId"
                label="Document ID"
                type="number"
                required
                rules={documentIdRules}
                data-cy="document-id-input"
              />
            </Grid>
            <Grid item xs={6} data-cy="document-title">
              <DisplayKTDocument documentId={memoizedDocumentId} type="" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={4}>
                <FormStringInput
                  control={control}
                  name="requiredSampleCount"
                  label="Sample Count"
                  type="number"
                  required
                  rules={sampleCountRules}
                  data-cy="sample-count-input"
                />
                <FormAutocompleteInput
                  control={control}
                  name="consumables"
                  label="Consumables"
                  disabled={partList?.length === 0}
                  multiple
                  options={partOptions}
                  data-cy="consumables-input"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormParametersInput
                control={control}
                name="parameters"
                // defaultValue needed for the internal variable watcher
                defaultValue={defaultValues.parameters}
                clearErrors={clearErrors}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              id="submitControlledProcess"
              data-cy="submit-button"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {buttonText}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

ControlledProcessForm.propTypes = {
  process: PropTypes.object,
  equipment: PropTypes.object,
  partList: PropTypes.array.isRequired,
  setTableReload: PropTypes.func,
  disabled: PropTypes.bool,
  'data-cy': PropTypes.string,
};

export default ControlledProcessForm;
