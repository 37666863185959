import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function FormStringInput({
  // Props
  control,
  name,
  label,
  type = 'text',
  textAlign = 'left',
  variant = 'standard',
  size,
  required = false,
  disabled = false,
  options,
  rules,
  rows,
  'data-cy': dataCy,
}) {
  const render = React.useCallback(
    ({ field: { onChange, name: fieldName, value }, fieldState: { error } }) => (
      <TextField
        required={required}
        label={label}
        id={fieldName}
        type={type}
        slotProps={{
          htmlInput: { sx: { textAlign } },
        }}
        select={!!options}
        variant={variant}
        size={size}
        disabled={disabled}
        fullWidth
        value={value}
        multiline={rows > 1}
        rows={rows}
        onChange={onChange}
        error={!!error}
        helperText={error?.message}
        data-cy={dataCy}
      >
        {!!options &&
          options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.value ?? option.id}
              disabled={option.disabled}
              id={`${fieldName}-${option.id}`}
              data-cy={`${fieldName}-${option.id}`}
            >
              {option.name}
            </MenuItem>
          ))}
      </TextField>
    ),
    [dataCy, disabled, label, options, required, rows, size, textAlign, type, variant]
  );
  return (
    <Controller name={name} control={control} shouldUnregister render={render} rules={rules} />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  /**
   * Type of the `input` element
   */
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'search', 'tel', 'url']),

  /**
   * Pass `textAlign` to the htmlInput
   */
  textAlign: PropTypes.string,

  /**
   * Control the rendered variant of the MUI TextField component
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),

  /**
   * Control the rendered size of the MUI TextField component
   */
  size: PropTypes.oneOf(['medium', 'small']),

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the input is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `options` is defined it enables a select menu with the options defined by `options`.
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * If `rows` is set, the text input will be multiline and have row count equal to `rows`.
   */
  rows: PropTypes.number,

  /**
   * Selector for E2E testing in Cypress
   */
  'data-cy': PropTypes.string,
};

export default FormStringInput;
