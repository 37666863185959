import React from 'react';
import PropTypes from 'prop-types';

import OpenInNew from '@mui/icons-material/OpenInNew';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function ExternalLink({
  // Props
  link,
  title,
}) {
  return (
    <Link href={link} target="_blank" rel="noreferrer" underline="always">
      <Stack direction="row" spacing={0.25} alignItems="center">
        <Typography>{title}</Typography>
        <OpenInNew fontSize="small" />
      </Stack>
    </Link>
  );
}

ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default React.memo(ExternalLink);
