import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import FormStringInput from './common/FormStringInput.jsx';

const relativeOptions = [
  { id: '>', name: 'Greater than' },
  { id: '>=', name: 'Greater than or equal to' },
  { id: '=', name: 'Equal to' },
  { id: '<=', name: 'Less than or equal to' },
  { id: '<', name: 'Less than' },
];

const inequalityOptions = [
  { id: '>', name: 'Greater than' },
  { id: '<', name: 'Less than' },
];

const negatableOptions = [
  { id: 'select', name: '-- Select Option --', value: '' },
  { id: '+', name: 'Include' },
  { id: '-', name: 'Exclude' },
];
function SearchHelperRow({
  // Props
  label,
  variant,
  control,
  keyword,
  options,
  autoFillValues,
}) {
  if (variant !== 'global' && !autoFillValues.some((el) => el.includes(keyword))) {
    throw new Error('Misconfigured search helper');
  }
  return (
    <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
      <Grid size={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography>{label}</Typography>
      </Grid>
      {variant === 'relative' && (
        <Grid size={{ xs: 6, sm: 4, md: 3 }}>
          <FormStringInput
            control={control}
            name={`${keyword}-relative`}
            options={relativeOptions}
            data-cy={`${keyword}-relative-search-input`}
          />
        </Grid>
      )}
      {variant === 'inequality' && (
        <Grid size={{ xs: 6, sm: 4, md: 3 }}>
          <FormStringInput
            control={control}
            name={`${keyword}-inequality`}
            options={inequalityOptions}
            data-cy={`${keyword}-inequality-search-input`}
          />
        </Grid>
      )}
      {(variant === 'negatable' || variant === 'toggle') && (
        <Grid size={{ xs: 6, sm: 4, md: 3 }} sx={{ mt: 2 }}>
          <FormStringInput
            control={control}
            name={`${keyword}-negatable`}
            options={negatableOptions}
            data-cy={`${keyword}-negatable-search-input`}
          />
        </Grid>
      )}
      {variant !== 'toggle' && (
        <Grid size="grow">
          <FormStringInput
            control={control}
            name={keyword}
            label={label}
            options={options}
            data-cy={`${keyword}-search-input`}
          />
        </Grid>
      )}
    </Grid>
  );
}

SearchHelperRow.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired,
  options: PropTypes.array,
  autoFillValues: PropTypes.array,
};

export default SearchHelperRow;
