import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

function DialogTitleBar({
  // Props
  title,
  Icon,
  iconColor = 'inherit',
  children,
}) {
  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
            {Icon && <Icon color={iconColor} />}
            <Box>{title}</Box>
          </Stack>
          {children}
        </Stack>
      </DialogTitle>
      <Divider sx={{ bgcolor: 'secondary.main' }} />
    </>
  );
}

DialogTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  iconColor: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(DialogTitleBar);
