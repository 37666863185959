/*  no-param-reassign */
import React from 'react';

import SearchIcon from '@mui/icons-material/Search';

import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';

import SearchContext from '../contexts/SearchContext.jsx';
import SearchHelper from './SearchHelper.jsx';

//---------------------------------------------------------------------------
function SearchBar() {
  const { search, setSearch, searchFields } = React.useContext(SearchContext);
  const theme = useTheme();
  const anchorEl = React.useRef();
  const inputRef = React.useRef();

  const autoFillValues = React.useMemo(() => {
    if (['', undefined, null].includes(searchFields)) {
      return null;
    }
    return Object.keys(searchFields).flatMap((key) => {
      if (typeof searchFields[key] === 'object' && !Array.isArray(searchFields[key])) {
        return Object.keys(searchFields[key]).map((value) => `${key}:${value}`);
      }
      return `${key}:`;
    });
  }, [searchFields]);

  const handleSearch = React.useCallback(
    (event) => {
      setSearch(event.target.value);
      window.scrollTo(0, 0);
    },
    [setSearch]
  );

  //---------------------------------------------------------------------------
  // Autocomplete support
  //---------------------------------------------------------------------------
  const autoCompleteSearch = React.useMemo(() => {
    const index = search.lastIndexOf(' ');
    if (index > 0) {
      return search.substring(index + 1, search.length);
    }
    return search;
  }, [search]);

  const autoCompleteMatches = React.useMemo(() => {
    if (!autoFillValues) {
      return [];
    }
    if (!autoCompleteSearch.length) {
      return [];
    }
    if (autoFillValues.some((val) => val === autoCompleteSearch)) {
      return [];
    }
    return autoFillValues.filter((val) => val.startsWith(autoCompleteSearch));
  }, [autoCompleteSearch, autoFillValues]);

  const handleAutoComplete = React.useCallback(
    (event) => {
      const index = search?.lastIndexOf(' ');
      if (index > 0) {
        setSearch(`${search.substring(0, index)} ${event.target.id}`);
      } else {
        setSearch(event.target.id);
      }
      inputRef.current.focus();
    },
    [search, setSearch]
  );

  if (!autoFillValues) {
    return null;
  }

  return (
    <Grid container sx={{ alignItems: 'center', maxWidth: '800px' }}>
      <Grid size="grow">
        <FilledInput
          color="primary"
          fullWidth
          ref={anchorEl}
          inputRef={inputRef}
          sx={{
            color: theme.palette.common.white,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            margin: '5px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            '&.Mui-focused': {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
          }}
          disableUnderline
          inputProps={{ sx: { py: '10px' } }}
          data-cy="search-bar"
          startAdornment={
            <InputAdornment sx={{ color: theme.palette.common.white }} position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search here..."
          value={search}
          onChange={handleSearch}
          endAdornment={
            <InputAdornment sx={{ color: theme.palette.common.white }} position="end">
              <SearchHelper anchorEl={anchorEl} autoFillValues={autoFillValues} />
            </InputAdornment>
          }
        />
        {autoFillValues?.length > 0 && (
          <Popper
            anchorEl={inputRef.current}
            open={autoCompleteMatches.length > 0}
            placement="bottom-start"
            sx={{ zIndex: 1500, boxShadow: 2 }}
          >
            <Paper p={0.5}>
              {autoCompleteMatches.map((value) => (
                <MenuItem key={value} id={value} onClick={handleAutoComplete}>
                  {value}
                </MenuItem>
              ))}
            </Paper>
          </Popper>
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(SearchBar);
