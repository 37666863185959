import React from 'react';
import PropTypes from 'prop-types';

export function readableMeasurement({
  // Props
  value,
  units,
}) {
  if ([null, undefined].includes(value)) {
    return '-';
  }
  if (units === 'unitless') {
    return value.toFixed(2);
  }
  return `${value.toFixed(2)} ${units}`;
}

readableMeasurement.propTypes = {
  value: PropTypes.number,
  units: PropTypes.string,
};

export default React.memo(readableMeasurement);
