import React from 'react';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ButtonBase from '@mui/material/ButtonBase';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

function ColumnHeader({
  // Props
  id,
  display,
  justify = 'start',
  sortField,
  reverseSort,
  onClick,
}) {
  const theme = useTheme();

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [id, onClick]);

  const selected = React.useMemo(() => sortField === id, [id, sortField]);

  const SortIndicator = React.useMemo(() => {
    if (selected) {
      if (reverseSort) {
        return ArrowDownward;
      }
      return ArrowUpward;
    }
    return Icon;
  }, [reverseSort, selected]);

  return (
    <ButtonBase
      id={id}
      disabled={!onClick}
      onClick={handleClick}
      sx={{
        width: '100%',
        justifyContent: justify,
        textAlign: justify,
        color: theme.palette.text.secondary,
        my: 1,
        ...(selected && { paddingRight: 0.5 }),
      }}
    >
      <Typography
        sx={{
          display: 'block',
          wordBreak: 'break-word',
          ...(selected && { fontWeight: 'bolder' }),
        }}
      >
        {display}
      </Typography>
      <SortIndicator sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }} />
    </ButtonBase>
  );
}

ColumnHeader.propTypes = {
  id: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  justify: PropTypes.string,
  sortField: PropTypes.string,
  reverseSort: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ColumnHeader;
