import React from 'react';

import { blue, blueGrey, lightBlue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLocalStorage } from '@tzmedical/react-hooks';

const useCustomTheme = () => {
  //---------------------------------------------------------------------------
  // Check the OS settings to see if we need to use Dark Mode
  //---------------------------------------------------------------------------
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [storedTheme, setStoredTheme] = useLocalStorage('tz-connect-selected-theme', 'system');

  const handleSelectTheme = React.useCallback(
    (event, newTheme) => {
      if (newTheme !== null) {
        setStoredTheme(newTheme);
      }
    },
    [setStoredTheme]
  );

  const isDarkMode = React.useMemo(
    () => storedTheme === 'dark' || (storedTheme === 'system' && prefersDarkMode),
    [prefersDarkMode, storedTheme]
  );

  //---------------------------------------------------------------------------
  // Return a usable theme object each time the prefers-color-scheme value changes
  //---------------------------------------------------------------------------
  return React.useMemo(() => {
    return createTheme({
      handleSelectTheme,
      selectedTheme: storedTheme,
      palette: {
        mode: isDarkMode ? 'dark' : 'light',
        primary: {
          light: '#58a5ef',
          main: lightBlue[800],
          dark: '#004c8b',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#80d6ff',
          main: blue[400],
          dark: '#0077c2',
          contrastText: '#ffffff',
        },
        tertiary: {
          light: '#62727b',
          main: blueGrey[800],
          dark: '#102027',
          contrastText: '#ffffff',
        },
        error: {
          main: '#c62828',
        },
        warning: {
          main: '#ffab00',
        },
        info: {
          main: blue[400],
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      typography: {
        h5: {
          fontWeight: '500',
        },
      },
    });
  }, [handleSelectTheme, storedTheme, isDarkMode]);
};

export default useCustomTheme;
