import React from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { blueberryTwilightPalette, ChartsTooltip, ChartsXAxis, ChartsYAxis } from '@mui/x-charts';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';

import axios from '../axiosClient.js';
import useControlLimits from '../hooks/useControlLimits.jsx';
import { readableMeasurement } from './common/ReadableMeasurement.jsx';
import { formatDate } from './FormattedDate.jsx';

function ControlChart({
  // Props
  process,
  processId,
  testMin,
  testMax,
  liveData,
}) {
  const [fetchedData, setFetchedData] = React.useState(process?.Measurements || []);
  const [units, setUnits] = React.useState(process?.TestUnits);

  const theme = useTheme();

  const { mean, upperLimit, lowerLimit, xValues, yValues } = useControlLimits(
    fetchedData,
    liveData
  );

  const fetchData = React.useCallback(async () => {
    if (!processId || process) {
      return;
    }

    try {
      const { data } = await axios({
        method: 'GET',
        url: `/api/processes`,
        params: {
          id: processId,
          include: ['measurements'],
        },
      });

      const [{ Measurements, TestUnits }] = data;

      setFetchedData(Measurements);
      setUnits(TestUnits);
    } catch (error) {
      setFetchedData([]);
    }
  }, [process, processId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Instead of plotting the X-axis as a timeline where x values are spaced based on their relative values,
  // Just use the dates as labels, with the location being based on the index of the data
  const xAxis = React.useMemo(() => {
    return [
      {
        valueFormatter: (index) => {
          if (Number.isInteger(index)) {
            return formatDate({ dateString: xValues[index] });
          }
          return '';
        },
        data: xValues.map((date, index) => index),
        tickNumber: xValues.length,
      },
    ];
  }, [xValues]);

  // Plot the measurement data as a single time series
  const seriesList = React.useMemo(() => {
    return [
      {
        type: 'line',
        data: yValues,
        id: 'value',
        // eslint-disable-next-line react/no-unstable-nested-components
        valueFormatter: (value) => readableMeasurement({ value, units }),
      },
    ];
  }, [units, yValues]);

  // Color the Y-axis based on whether the values are inside or outside the expected ranges
  const yAxis = React.useMemo(
    () =>
      seriesList.map((series, index) => {
        const goodColor = blueberryTwilightPalette(theme.mode)[index];
        const badColor = theme.palette.error.main;
        const lowerLimits = [testMin, lowerLimit].filter((el) => !Number.isNaN(Number(el)));
        const upperLimits = [testMax, upperLimit].filter((el) => !Number.isNaN(Number(el)));
        return {
          id: series.id,
          valueFormatter: series.valueFormatter,
          min: Math.min(...lowerLimits, ...yValues, 0),
          max: Math.max(...upperLimits, ...yValues),
          colorMap: {
            type: 'piecewise',
            thresholds: [Math.max(...lowerLimits), Math.min(...upperLimits)],
            colors: [badColor, goodColor, badColor],
          },
        };
      }),
    [
      lowerLimit,
      seriesList,
      testMax,
      testMin,
      theme.mode,
      theme.palette.error.main,
      upperLimit,
      yValues,
    ]
  );

  if (!processId) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50%',
        }}
        data-cy="no-chart-data"
      >
        <p>Select a controlled process to view the chart.</p>
      </Box>
    );
  }

  if (xValues.length === 0) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50%' }}
        data-cy="no-chart-data"
      >
        <p>Insufficient data to display chart</p>
      </Box>
    );
  }

  return (
    <Box
      data-mean={mean}
      data-upper-control-limit={upperLimit}
      data-lower-control-limit={lowerLimit}
      data-live-value={yValues[yValues.length - 1] || 'undefined'}
      data-cy="chart-container"
    >
      <ResponsiveChartContainer
        id="graph-with-measurement-data"
        xAxis={xAxis}
        series={seriesList}
        yAxis={yAxis}
        skipAnimation
        height={480}
        margin={{ left: 90, right: 100 }}
      >
        <LinePlot />
        <MarkPlot />
        <ChartsXAxis />
        <ChartsYAxis />
        <ChartsReferenceLine
          y={testMin}
          label="Min"
          labelAlign="start"
          lineStyle={{ stroke: theme.palette.error.main, strokeDasharray: '12 12' }}
        />
        {lowerLimit && (
          <ChartsReferenceLine
            y={lowerLimit}
            label="Lower Control Limit"
            labelAlign="start"
            lineStyle={{ stroke: theme.palette.warning.main, strokeDasharray: '8 8' }}
          />
        )}
        {upperLimit && (
          <ChartsReferenceLine
            y={upperLimit}
            label="Upper Control Limit"
            labelAlign="start"
            lineStyle={{ stroke: theme.palette.warning.main, strokeDasharray: '8 8' }}
          />
        )}
        <ChartsReferenceLine
          y={testMax}
          label="Max"
          labelAlign="start"
          lineStyle={{ stroke: theme.palette.error.main, strokeDasharray: '12 12' }}
        />
        <ChartsTooltip />
      </ResponsiveChartContainer>
    </Box>
  );
}

export default ControlChart;
